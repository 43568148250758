<template>
  <div>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <div v-if="!quoteSent">
        <v-row class="mb-10 justify-center">
          <v-col cols="12" md="8" lg="6">
            <div class="text-center">
              <h2 class="mb-2">Request a Quote</h2>
              <p class="grey--text mb-7">Let us know what is your needs to be done.</p>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation class="pt-4">
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field v-model="name" :rules="nameRules" label="Name" required filled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field v-model="company" label="Company" filled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field v-model="email" :rules="emailRules" label="Email" required filled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Phone number"
                    type="number"
                    v-model="phone"
                    :rules="phoneRules"
                    required
                    filled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="mb-8" />
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-select
                    v-model="buildingType"
                    :items="buildingTypeList"
                    item-text="text"
                    item-value="value"
                    :rules="[(v) => !!v || 'Building Type is required']"
                    label="Building Type"
                    required
                    filled
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Building Area (m2)"
                    type="number"
                    v-model="buildingArea"
                    :rules="buildingAreaRules"
                    required
                    filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-select
                    v-model="numberOfRoom"
                    :items="numberOfRoomList"
                    :rules="[(v) => !!v || 'Number of Rooms is required']"
                    label="Number of Rooms"
                    required
                    filled
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" class="py-0">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="appointmentDate"
                        label="Appointment Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        filled
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="appointmentDate" @input="menu2 = false" color="primary"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-textarea filled name="input-7-4" label="Message" v-model="message"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" class="py-0">
                  <v-file-input
                    accept="application/msword, application/vnd.ms-excel,
                    application/vnd.ms-powerpoint, text/plain, application/pdf, image/*"
                    label="Attachment"
                    filled
                    disabled
                  ></v-file-input>
                </v-col>
                <v-col class="d-flex justify-end py-0">
                  <v-btn
                    class="btn-full"
                    :disabled="!valid || sending"
                    color="primary"
                    :loading="sending"
                    @click="sendQuote"
                    >Send Quote</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>

        <div class="text-center ma-2">
          <v-snackbar v-model="quoteFailed" color="red accent-2">
            Failed to send quote. Please try again.

            <template v-slot:action="{ attrs }">
              <v-btn text v-bind="attrs" @click="quoteFailed = false"> Close </v-btn>
            </template>
          </v-snackbar>
        </div>
      </div>
      <div v-else class="d-flex justify-center">
        <div class="text-center">
          <v-img :src="require('../assets/images/send-1.svg')" class="my-3" contain height="128" />
          <h2>Your quote has been sent</h2>
          <p class="grey--text">
            You will receive an email if and when your quote is approved,
            <br />
            and we can continue to discuss your needs.
          </p>
          <v-btn color="primary" to="/" class="mt-2">Back to Home</v-btn>
          <p class="grey--text mt-5 mb-2">Or</p>
          <v-btn color="primary" text @click="quoteSent = false">Send another quote</v-btn>
        </div>
      </div>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import 'isomorphic-fetch';

export default {
  components: {
    Footer,
  },
  data: () => ({
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        to: '/',
      },
      {
        text: 'Request a Quote',
        disabled: true,
        to: '/request-a-quote',
      },
    ],
    sending: false,
    quoteFailed: false,
    quoteSent: false,
    valid: true,
    name: '',
    nameRules: [(v) => !!v || 'Name is required'],
    email: '',
    emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    phone: '',
    phoneRules: [
      (v) => !!v || 'Phone number is required',
      (v) => (v && v.length > 5) || 'Phone number must be at least 5 digits',
      (v) => (v && v.length <= 16) || 'Phone must be less than 16 digits',
    ],
    buildingArea: '',
    buildingAreaRules: [
      (v) => !!v || 'Building area is required',
      (v) => (v && v > 10) || 'Building area must be at least 10 m2',
      (v) => (v && v < 5000) || 'Building area must be less than 5000 m2',
    ],
    numberOfRoom: '',
    company: '',
    message: '',
    buildingTypeList: [
      {
        text: 'Apartment',
        value: 'apartment',
      },
      {
        text: 'Hotel',
        value: 'hotel',
      },
      {
        text: 'Land House',
        value: 'land-house',
      },
      {
        text: 'Office',
        value: 'office',
      },
      {
        text: 'Villa',
        value: 'villa',
      },
      {
        text: 'Other',
        value: 'other',
      },
    ],
    numberOfRoomList: ['1 - 5', '6 - 10', '> 10'],
    appointmentDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    buildingType: null,
  }),

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    sendQuote() {
      const content = {
        purpose: 'request-quote',
        name: this.name,
        company: this.company,
        email: this.email,
        phone: this.phone,
        message: this.message,
        buildingType: this.buildingType,
        buildingArea: this.buildingArea,
        numberOfRoom: this.numberOfRoom,
        appointmentDate: this.appointmentDate,
      };

      this.$refs.form.validate();

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(content),
        redirect: 'follow',
      };

      if (this.$refs.form.validate()) {
        this.sending = true;
        fetch(`${process.env.VUE_APP_API_BASE_URL}/email`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            this.sending = false;
            if (result.status === 'Success') {
              this.$refs.form.reset();
              this.quoteSent = true;
              window.scrollTo(0, 0);
            } else {
              this.quoteFailed = true;
            }
          })
          .catch((err) => {
            this.sending = false;
            this.quoteFailed = true;
            console.log('error: ', err);
          });
      }
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<style scoped>
@media (max-width: 1264px) {
  .btn-full {
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
    max-width: auto;
  }
}
</style>
